



























































































































































































































import { Component, Vue } from "vue-property-decorator";
import homeTab from "@/views/home/components/homeTab.vue"; // @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { newsListApi, productList } from "@/api";
const { WOW } = require("wowjs");
@Component({
    components: {
        homeTab,
        swiper,
        swiperSlide,
    },
    filters: {
        formatTime(val: any, type: number) {
            if (val) {
                let str = val.split(" ")[0];
                str = str.split("-");
                if (type == 0) {
                    return str[0];
                } else {
                    return `${str[1]}-${str[2]}`;
                }
            }
        },
        filterTime(val: any) {
            return val && val.slice(5, 10);
        },
        extract(content: String) {
            if (!content) return;
            content = content.replace(/<.*?>/g, "");
            return content.replace(/&.*?;/g, "");
        },
    },
})
export default class Home extends Vue {
    public newsList = [];
    public list = [];
    public newsLeft = {
        title: "",
        content: "",
        coverUrl: "",
    };
    public sortList = [
        {
            name: "当季新品",
            id: 1,
        },
        {
            name: "套件类",
            id: 2,
        },
        {
            name: "被芯类",
            id: 3,
        },
        {
            name: "床品搭配",
            id: 4,
        },
    ];
    public swiperOption = {
        slidesPerView: "auto",
        autoplay: false,
        loop: false,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination-1",
            clickable: true,
        },
    };
    public swiperOption1 = {
        slidesPerView: "3",
        autoplay: false,
        loop: false,
        navigation: {
            nextEl: ".swiper-button-next1",
            prevEl: ".swiper-button-prev1",
        },
        pagination: {
            el: ".swiper-pagination-1",
            clickable: true,
        },
    };
    public tabInde: number = 0;
    public newsTabIndex: number = 0;
    public newsProIndex: number = 0;

    public adviceVisible = false;
    public async mounted() {
        this.$nextTick(() => {
            new WOW({ mobile: true, live: true, scrollContainer: ".layout-content" }).init();
        });

        this.getProList(1);

        this.getNewsList();
    }

    //获取产品
    public handelTab(index: any, id: any) {
        this.newsProIndex = index;
        this.getProList(id);
    }
    public handelJump(item: any, index: Number) {
        console.log(item);
        let nextJson;
        if (Number(index) + 1 < this.list.length) {
            nextJson = this.list[Number(index) + 1];
        }
        this.$router.push({ path: "/productDetail", query: { id: item.id } });
    }
    public getProList(productType: any) {
        productList({
            pageSize: 99,
            pageNum: 1,
            productType,
        }).then((res) => {
            this.list = res;
        });
    }

    //新闻tab切换
    public handelNewsTab(type: any) {
        this.newsTabIndex = type;
        this.getNewsList(type == 0 ? "1" : "2");
    }

    //获取新闻列表
    public async getNewsList(id: string = "") {
        let res = await newsListApi({ orderByReadOrTime: id, pageNum: 1, pageSize: 10 });
        this.newsList = res.list || [];
        this.newsLeft = res.list[0];
        this.newsList = this.newsList.filter((item, index) => index > 0 && index < 4);
    }

    public metaInfo = {
        title: "",
        meta: [
            {
                name: "简单睡眠，简单睡眠家纺，国民家纺，国民家纺品牌，床上用品，全棉四件套，磨毛四件套，冬被，夏被，春秋被，家纺四件套",
                content: "国民家纺品牌简单睡眠，主营套件、冬被、夏被、春秋被、枕头、凉席、毛绒玩具等家居床上用品、婚庆用品与纺织工艺品。",
            },
        ],
    };
}
