
























import { Vue, Ref, Component } from "vue-property-decorator";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import applyForm from "@/components/applyForm.vue";
import "swiper/dist/css/swiper.css";
@Component({
    components: {
        swiper,
        swiperSlide,
        applyForm,
    },
})
export default class homeTab extends Vue {
    @Ref("mySwiper") mySwiper: any;
    public swiperOption = {
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        spaceBetween: 60,
        initialSlide: 0,
        autoplay: {
            disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
            delay: 4200,
        },
        speed: 800,
        //滑动之后回调函数
        on: {
            slideChangeTransitionStart: () => {
                this.getStep();
            },
        },
    };
    public dialogVisible = false;
    public tabInde: number = 0;

    public handleClose() {
        this.dialogVisible = false;
    }

    //on中写了this.stepIndex无法生效？
    public getStep() {
        this.tabInde = this.mySwiper.swiper.activeIndex;
    }

    public open(url: string) {
        window.open(url);
    }
}
