




































import { Vue, Component } from "vue-property-decorator";
import { trial } from '@/api'
@Component
export default class applyForm extends Vue {
    public formData: any = {
        unitName: '',
        linkUser: '',
        phone: '',
        email: '',
        post: '',
        content: '',
    }

    public async handelSubmit() {
        await trial(this.formData).then(res => {
            // this.$message.success('提交成功')
            this.formData = {
                unitName: '',
                linkUser: '',
                phone: '',
                email: '',
                post: '',
                category: '',
                content: '',
            };
            if((this as any).$parent.handleClose) {
                (this as any).$parent.handleClose()
            }
        })

    }
}
